import React, { useState } from "react";
import "./App.css";

import Details from "./pages/Details";
import GrossSalaryInput from "./pages/Input";

const App: React.FC = () => {
  const [inputPage, setInputPage] = useState<boolean>(true);
  const [grossValue, setGrossValue] = useState<number>(0);

  const changeActivePage = (flag: boolean): void => {
    setInputPage(flag);
  };

  return (
    <div>
      {inputPage ? (
        <GrossSalaryInput
          changeActivePage={changeActivePage}
          setGrossSalary={(value) => setGrossValue(value)}
        />
      ) : (
        <Details grossSalary={grossValue} changeActivePage={changeActivePage} />
      )}
    </div>
  );
};

export default App;
