import React from "react";

import Style from "./Cost.module.css";

type Props = {
  cost: string;
  tooltip: React.ReactElement;
};

const Cost: React.FC<Props> = ({ children, cost, tooltip }) => {
  return (
    <div className={Style.costContainer}>
      <div className={Style.costDescription}>{children}</div>
      <div className={Style.cost}>-{cost}</div>
      {tooltip}
    </div>
  );
};

export default Cost;
