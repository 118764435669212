import React from "react";

import Styles from './SalaryBlock.module.css';
import { ReactComponent as RedDollar } from "./Icons/red-dollar.svg";
import { ReactComponent as GreenDollar } from "./Icons/green-dollar.svg";

type Props = {
  grossSalary?: boolean;
};

const SalaryBlock: React.FC<Props> = ({ children, grossSalary }) => {

  return (
    <div className={Styles.salaryContainer}>
      <div className={Styles.details}>
        <span>{grossSalary ? 'БРУТО' : 'НЕТО'}</span>
        <span className={Styles.salary}>{children}</span>
      </div>
      <div className={Styles.dollarContainer}>
        {grossSalary ? <RedDollar /> : <GreenDollar />}
      </div>
    </div>
  );
};

export default SalaryBlock;
