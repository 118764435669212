import { useEffect, useRef } from "react";

type ShortcutProperties = {
  altKey: boolean;
  ctrlKey: boolean;
  shiftKey: boolean;
  key: string;
};

type CallbackFunction = (event: KeyboardEvent) => void;

/**
 * Registers an event listener on the document and calls the callback function when the correct event is captured.
 * `key` is compared against `event.key`
 * @param {ShortcutProperties} shortcut
 * @param {CallbackFunction} callback
 */
const useShortcut = (
  shortcut: ShortcutProperties,
  callback: CallbackFunction
) => {
  const callbackRef = useRef<CallbackFunction>(callback);

  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    const eventHandler = (event: KeyboardEvent) => {
      if (
        event.altKey === shortcut.altKey &&
        event.ctrlKey === shortcut.ctrlKey &&
        event.shiftKey === shortcut.shiftKey &&
        event.key === shortcut.key
      ) {
        event.preventDefault();
        callbackRef.current(event);
      }
    };

    document.addEventListener("keydown", eventHandler);

    return () => document.removeEventListener("keydown", eventHandler);
  }, [shortcut]);
};

export { useShortcut };
