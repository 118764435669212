// http://www.ujp.gov.mk/mk/vodic/category/641
// http://www.ujp.gov.mk/mk/plakjanje/licno_osloboduvanje/2021
import React from "react";

import Style from "./Footer.module.css";

const Footer: React.FC = () => {
  return (
    <footer className={Style.footer}>
      <p>
        Апликацијата врши пресметка на нето плата, задолжителни придонеси и
        данок на личен доход. Оваа апликација е создадена како хоби проект и
        креаторот на апликацијата не е одговорен за точноста на пресметките.
        Сите направени пресметки се од информативен карактер. Креаторот на
        апликацијата нема никаква поврзаност со УЈП.
      </p>
      <p>
        Пресметките се извршени според одредбите објавени од страна на УЈП во
        2021 година.
      </p>
      <br />
      <a href="http://www.ujp.gov.mk/mk/vodic/category/641" target="_blank" rel="noreferrer">
        Стапки по видови социјални придонеси и просечна плата по работник
      </a>
      <br />
      <a
        href="http://www.ujp.gov.mk/mk/plakjanje/licno_osloboduvanje/2021"
        target="_blank"
        rel="noreferrer"
      >
        Преглед на лично ослободување за 2021 година
      </a>
    </footer>
  );
};

export default Footer;
