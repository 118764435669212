import React, { useState, useRef } from "react";
import { useShortcut } from "../hooks/useShortcut";

import Style from "./Input.module.css";

type Props = {
  setGrossSalary: (value: number) => void;
  changeActivePage: (flag: boolean) => void;
};

const GrossSalaryInput: React.FC<Props> = ({
  setGrossSalary,
  changeActivePage,
}) => {
  const [value, setValue] = useState<number>(0);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    setGrossSalary(value);
    changeActivePage(false);
  };

  const handleInputFocus = () => {
    inputRef.current?.focus();
  };

  useShortcut(
    { altKey: false, ctrlKey: false, shiftKey: false, key: "/" },
    handleInputFocus
  );

  useShortcut(
    { altKey: false, ctrlKey: true, shiftKey: false, key: "k" },
    handleInputFocus
  );

  return (
    <div className="container">
      <h1>Плата</h1>
      <div>
        <div className={Style.grossSalaryContainer}>
          <form onSubmit={handleSubmit}>
            <label htmlFor="grossSalary">Внеси месечна бруто плата</label>
            <input
              ref={inputRef}
              type="number"
              min="22146"
              name="grossSalary"
              id="grossSalary"
              placeholder="пр. 22146"
              value={value ? value : ""}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setValue(parseInt(event.target.value))
              }
              required
            />
            <button type="submit" className={Style.continue}>
              СЛЕДНО
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default GrossSalaryInput;
