import React, { useEffect, useState } from "react";

import Style from "./Details.module.css";

import Cost from "../components/Cost";
import Tooltip from "../components/Tooltip";
import Footer from "../components/Footer";
import SalaryBlock from "../components/SalaryBlock";

type Props = {
  grossSalary: number;
  changeActivePage: (flag: boolean) => void;
};

const Details: React.FC<Props> = ({ grossSalary, changeActivePage }) => {
  const [netSalary, setNetSalary] = useState<number>(0);
  const [pio, setPio] = useState<number>(0); // Пензиско и инвалидско осигурување
  const [zzo, setZzo] = useState<number>(0); // Здравствено осигурување
  const [dpz, setDpz] = useState<number>(0); // Дополнителен придонес за здравствено осигурување
  const [osn, setOsn] = useState<number>(0); // Задолжителен придонес за осигурување при невработеност
  const [dld, setDld] = useState<number>(0); // Данок на личен доход

  useEffect(() => {
    const subtotal =
      Math.round(grossSalary * 0.188) +
      Math.round(grossSalary * 0.075) +
      Math.round(grossSalary * 0.005) +
      Math.round(grossSalary * 0.012);
    const total = subtotal + (grossSalary - subtotal - 8438) * 0.1;

    setPio(Math.round(grossSalary * 0.188));
    setZzo(Math.round(grossSalary * 0.075));
    setDpz(Math.round(grossSalary * 0.005));
    setOsn(Math.round(grossSalary * 0.012));
    setDld(Math.round((grossSalary - subtotal - 8438) * 0.1));
    setNetSalary(Math.round(grossSalary - total));
  }, [grossSalary]);

  const formatCurrency = (value: number): string =>
    Intl.NumberFormat("en-MK", { style: "currency", currency: "MKD" }).format(
      value
    );

  return (
    <div className="container">
      <div className={Style.row}>
        <h1>Детали</h1>
        <button
          className={Style.backButton}
          onClick={() => changeActivePage(true)}
        >
          НАЗАД
        </button>
      </div>

      <section className={Style.sectionMargin}>
        <SalaryBlock>{formatCurrency(netSalary)}</SalaryBlock>
        <SalaryBlock grossSalary>{formatCurrency(grossSalary)}</SalaryBlock>
      </section>

      <section className={Style.sectionMargin}>
        <Cost
          cost={formatCurrency(pio)}
          tooltip={
            <Tooltip>
              <span>бруто</span> * <span>18.8%</span>
            </Tooltip>
          }
        >
          Задолжително пензиско и инвалидско осигурување
        </Cost>
        <Cost
          cost={formatCurrency(zzo)}
          tooltip={
            <Tooltip>
              <span>бруто</span> * <span>7.5%</span>
            </Tooltip>
          }
        >
          Задолжително здравствено осигурување
        </Cost>
        <Cost
          cost={formatCurrency(dpz)}
          tooltip={
            <Tooltip>
              <span>бруто</span> * <span>0.5%</span>
            </Tooltip>
          }
        >
          Дополнителен придонес за здравствено осигурување за случај на повреда
          на работа и професионално заболување
        </Cost>
        <Cost
          cost={formatCurrency(osn)}
          tooltip={
            <Tooltip>
              <span>бруто</span> * <span>1.2%</span>
            </Tooltip>
          }
        >
          Задолжителен придонес за осигурување во случај на невработеност
        </Cost>
        <Cost
          cost={formatCurrency(dld)}
          tooltip={
            <Tooltip>
              (<span>бруто</span> - <span>задолжителни трошоци</span> -{" "}
              <span>лично ослободување</span>) * <span>10%</span>
            </Tooltip>
          }
        >
          Данок на личен доход
        </Cost>
        <div className={Style.totalCostsContainer}>
          <span>Вкупно</span>
          <div className={Style.totalCosts}>
            -{formatCurrency(grossSalary - netSalary)}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Details;
